<template>
  <div @click="updateLocale(getCurrentLocaleData.lang === 'English' ? 'en' : 'ar')" class="flex justify-center items-center cursor-pointer border-cold-morning border border-solid rounded-full p-[6px]">
    <p :class="`cursor-pointer flex items-center ${isExpanded ? 'gap-[5px]' : 'gap-[6px]'}`">
      <img :src="i18n_locale_img" :alt="$i18n.locale"/>
      <span :class="`${isExpanded ? 'text-[9px]' : 'text-[12px] mb-[2px]' } text-stone-cold`">{{ isExpanded || smallScreen ? getCurrentLocaleData.lang : getCurrentLocaleData.shortLang }}</span>
    </p>
  </div>
</template>
  
<script>
export default {
  props: ['isExpanded', 'smallScreen'],
  computed: {
    i18n_locale_img () {
      // Use below code to dynamically fetch image instead of if-else
      // NOTE: We used if-else because laravel throws error in 'yarn prod'.
      // If you are not using laravel + Vue, you can use below code to dynamically get image
      // return require(`@/assets/images/flags/${this.$i18n.locale}.png`)
  
      const locale = this.$i18n.locale
      if (locale === 'en')      return require('@/assets/images/flags/ar.svg')
      else if (locale === 'ar') return require('@/assets/images/flags/en.svg')
      else return require('@/assets/images/flags/en.png')
    },
    getCurrentLocaleData () {
      if (this.$route.params.lang === 'en') return { flag: 'ar', lang: 'العربية', shortLang: 'ع'} 
      else return { flag: 'us', lang: 'English', shortLang: 'E' }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$cookies.set('language', locale, '100y')
      localStorage.setItem('locale', locale)
      if (locale === 'ar') this.$vs.rtl = true
      else if (locale === 'en') this.$vs.rtl = false
      this.$router.push({
        params: {lang: this.$cookies.get('language')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('language')
    }
  }
}
</script>